import React from "react";
import { Link } from "react-router-dom";
import {
	IonContent,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonMenuToggle,
	IonImg,
	IonIcon,
	IonRow,
	IonCol,
	IonNote,
} from "@ionic/react";
import { useLocation } from "react-router-dom";
import { briefcaseSharp, logoInstagram, logoYoutube } from "ionicons/icons";

import flippedLogo from "assets/flip-flip.png";
import "./Menu.css";

interface AppPage {
	url: string;
	iosIcon: string;
	mdIcon: string;
	title: string;
}

const appPages: AppPage[] = [
	{
		title: "v i s i o n",
		url: "/vision",
		iosIcon: "",
		mdIcon: "",
	},
	{
		title: "shop",
		url: "/shop",
		iosIcon: briefcaseSharp,
		mdIcon: briefcaseSharp,
	},
	{
		title: "films",
		url: "/films",
		iosIcon: "",
		mdIcon: "",
	},
	{
		title: "fotos",
		url: "/fotos",
		iosIcon: "",
		mdIcon: "",
	},
	{
		title: "about",
		url: "/about",
		iosIcon: "",
		mdIcon: "",
	},
];

const Menu: React.FC = () => {
	const location = useLocation();

	return (
		<IonMenu contentId="main" type="overlay">
			<IonContent>
				<IonList id="inbox-list">
					<Link to="/home">
						<IonImg
							src={flippedLogo}
							style={{ width: 100, paddingLeft: 10, paddingBottom: 10 }}
						/>
					</Link>
					{appPages.map((appPage, index) => {
						return (
							<IonMenuToggle key={index} autoHide={false}>
								<IonItem
									className={
										location.pathname === appPage.url ? "selected" : ""
									}
									routerLink={appPage.url}
									routerDirection="none"
									lines="none"
									detail={false}
								>
									<IonLabel>{appPage.title}</IonLabel>
								</IonItem>
							</IonMenuToggle>
						);
					})}
				</IonList>
				<IonRow>
					<IonCol>
						<IonItem
							href="https://www.instagram.com/furisupesu/?hl=en"
							lines="none"
							button
							style={{ display: "inline-block" }}
						>
							<IonIcon src={logoInstagram} />
						</IonItem>
					</IonCol>
					<IonCol>
						<IonItem
							href="https://www.youtube.com/channel/UCs34MLS4KIYhCvTUmgL98SA"
							lines="none"
							button
							style={{ display: "inline-block" }}
						>
							<IonIcon src={logoYoutube} />
						</IonItem>
					</IonCol>
				</IonRow>
				<IonNote>© 2022 Furīsupēsu...</IonNote>
			</IonContent>
		</IonMenu>
	);
};

export default Menu;
