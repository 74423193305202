import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import MenuButton from "components/MenuButton";
import { fetchImages } from "components/Slide";
import Spinner from "assets/tess.gif";
import {
	IonPage,
	IonContent,
	IonItem,
	IonRow,
	IonCol,
	IonImg,
} from "@ionic/react";

interface Props {
	playVid: boolean;
}

// const titles = [
// 	"vision - (2018",
// 	""
// ]

const Vision = ({ playVid }: Props) => {
	const [urls, setUrls] = useState<string[]>([]);
	const [spinner, setSpinner] = useState<boolean>(true);

	useEffect(() => {
		fetchImages("vision").then((res) => {
			setUrls(res);
			setSpinner(false);
		});
	}, []);

	console.log(urls);
	return (
		<IonPage>
			{spinner ? (
				<IonRow>
					<IonCol> </IonCol>
					<IonCol>
						<IonItem
							color="clear"
							lines="none"
							style={{
								display: "block",
								marginLeft: "auto",
								marginRight: "auto",
								width: "100%",
								height: "50vh",
							}}
						>
							<IonImg src={Spinner} />
						</IonItem>
					</IonCol>
					<IonCol> </IonCol>
				</IonRow>
			) : (
				<IonContent>
					<MenuButton />
					<IonRow>
						<IonCol>
							{urls.map((url, index) => {
								return (
									<>
										<IonItem lines="none" key={index}>
											<ReactPlayer
												loop
												// playing={playVid}
												controls
												url={url}
												width="100%"
												height="100vh"
											/>
										</IonItem>
									</>
								);
							})}
						</IonCol>
					</IonRow>
				</IonContent>
			)}
		</IonPage>
	);
};

export default Vision;
