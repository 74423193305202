import axios from "axios";

export const getSyncProducts = () => {
	const dest =
		"https://us-central1-free-site-3.cloudfunctions.net/getSyncProducts";
	// "http://127.0.0.1:5001/free-site-3/us-central1/getSyncProducts";

	return axios({
		method: "get",
		url: dest,
		headers: { "Access-Control-Allow-Origin": "*" },
	})
		.then((response: any) => {
			// console.log(response.data);
			return response.data;
		})
		.catch((error: any) => {
			console.log(error);
		});
};

export const getSyncVariants = (id: string) => {
	// console.log(id);
	const dest =
		"https://us-central1-free-site-3.cloudfunctions.net/getSyncVariants";
	// "http://127.0.0.1:5001/free-site-3/us-central1/getSyncVariants";

	return axios({
		method: "post",
		url: dest,
		headers: { "Access-Control-Allow-Origin": "*" },
		data: { id: id },
	})
		.then((response: any) => {
			return response.data;
		})
		.catch((error: any) => {
			console.log(error);
		});
};

export const postOrders = (newOrder: object) => {
	const dest = "https://us-central1-free-site-3.cloudfunctions.net/createOrder";

	return axios({
		method: "post",
		url: dest,
		headers: { "Access-Control-Allow-Origin": "*" },
		data: newOrder,
	})
		.then((response: any) => {
			return response.data;
		})
		.catch((error: any) => {
			console.log(error);
		});
};
