import React from "react";
import { useEffect, useState } from "react";
import MenuButton from "components/MenuButton";
import Slide from "components/Slide";
import { fetchImages } from "components/Slide";
import Spinner from "assets/tess.gif";
import allProd from "assets/allProd.png";
import { db } from "../../firebase";
import {
	IonPage,
	IonContent,
	IonImg,
	IonItem,
	IonRow,
	IonCol,
} from "@ionic/react";

const Store = () => {
	const [products, setProducts] = useState<any[]>([]);
	const [spinner, setSpinner] = useState<boolean>(true);
	const [urls, setUrls] = useState<string[]>([]);
	// const [shirts, setShirts] = useState<any[]>([]);

	useEffect(() => {
		fetchImages("Printful Product Images").then(async (res) => {
			setUrls(res);
			let printfulProducts = await db.collection("printfulProducts").get();
			console.log(
				"printful products",
				printfulProducts.docs.map((doc) => doc.data())
			);
			setProducts(printfulProducts.docs.map((doc) => doc.data()));
			let shirtDocs = await db.collection("shirts").get();
			console.log(
				"firestore shirts",
				shirtDocs.docs.map((doc) => doc.data())
			);
			// setShirts(shirtDocs.docs.map((doc) => doc.data()));
			setSpinner(false);
		});
	}, []);

	return (
		<IonPage>
			<MenuButton />
			{spinner ? (
				<IonRow>
					<IonCol> </IonCol>
					<IonCol>
						<IonItem
							color="clear"
							lines="none"
							style={{
								display: "block",
								marginLeft: "auto",
								marginRight: "auto",
								width: "100%",
								height: "50vh",
							}}
						>
							<IonImg src={Spinner} />
						</IonItem>
					</IonCol>
					<IonCol> </IonCol>
				</IonRow>
			) : (
				<IonContent>
					{/* <IonItem lines="none" color="light"> */}
					<Slide
						urls={urls}
						title={allProd}
						transition="fade"
						color="light"
						autoplay={true}
						pager={false}
					/>
					{/* </IonItem> */}
					<IonRow>
						<IonCol>
							{/* {shirts.map((shirt, id) => {
								return (
									<>
										<Slide
											urls={shirt.images}
											note={shirt.name + " $" + shirt.price}
											// href={}
											transition="fade"
											color="none"
											autoplay={false}
											styleName="ion-padding-bottom ion-padding-top"
										/>
									</>
								);
							})} */}
							{products.map((item, index) => {
								const path = "/shop/" + item.external_id;
								return (
									<>
										<Slide
											urls={item.images}
											note={item.name + " $" + item.price}
											href={path}
											transition="fade"
											color="dark"
											autoplay={false}
											pager={item.images.length > 1 ? true : false}
											styleName="ion-padding-bottom ion-padding-top"
										/>
									</>
								);
							})}
						</IonCol>
					</IonRow>
				</IonContent>
			)}
		</IonPage>
	);
};

export default Store;
