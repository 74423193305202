import React, { useState, useEffect } from "react";
import MenuButton from "components/MenuButton";
import {
	fetchImages,
	// fetchImageData
} from "components/Slide";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import Spinner from "assets/tess.gif";
import {
	IonPage,
	IonContent,
	IonItem,
	IonRow,
	IonCol,
	IonImg,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
} from "@ionic/react";

const Fotos = () => {
	const [urls, setUrls] = useState<string[]>([]);
	// const [files, setFiles] = useState<any[]>([]);
	const [spinner, setSpinner] = useState<boolean>(true);
	const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
	useEffect(() => {
		fetchImages("bts").then((res) => {
			setUrls(res);
			setSpinner(false);
		});
		// fetchImageData("bts").then((res) => {
		// 	console.log("file", res[0].name);
		// 	let a = [];

		// 	res.map((file) => {
		// 		let url = "furisupesu.mo.cloudinary.net/bts/" + file.name;
		// 		a.push(url);
		// 	});

		// 	setFiles(a);
		// });
	}, []);

	// console.log("Files: ", files);

	const loadData = () => {
		setTimeout(() => {
			setInfiniteDisabled(true);
		}, 3000);
	};

	return (
		<IonPage>
			<MenuButton />
			{spinner ? (
				<IonRow>
					<IonCol> </IonCol>
					<IonCol>
						<IonItem
							color="clear"
							lines="none"
							style={{
								display: "block",
								marginLeft: "auto",
								marginRight: "auto",
								width: "100%",
								height: "50vh",
							}}
						>
							<IonImg src={Spinner} />
						</IonItem>
					</IonCol>
					<IonCol> </IonCol>
				</IonRow>
			) : (
				<IonContent>
					{urls.map((url, key) => {
						// console.log(key, file);
						return (
							<LazyLoadImage
								width="100%"
								// placeholderSrc="loading..."
								threshold={1000}
								src={url}
								effect="opacity"
							/>
						);
					})}
					<IonInfiniteScroll
						onIonInfinite={loadData}
						threshold="100%"
						disabled={isInfiniteDisabled}
					>
						<IonInfiniteScrollContent
							loadingSpinner="bubbles"
							loadingText="Loading more data..."
						></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			)}
		</IonPage>
	);
};

export default Fotos;
