import firebase from "firebase/app";
import "firebase/storage";
// import "firebase-admin/app";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
	apiKey: "AIzaSyCA6iixMu8nZ0k_GF8o1iPQYm3ASqjRo-A",
	authDomain: "free-site-3.firebaseapp.com",
	projectId: "free-site-3",
	storageBucket: "free-site-3.appspot.com",
	messagingSenderId: "724139254003",
	appId: "1:724139254003:web:78d84b7887c5a11a295786",
	measurementId: "G-Z6P2KLLZHD",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage().ref();

const db = firebase.firestore();

export { storage, db };
