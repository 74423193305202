import React from "react";
import { Link } from "react-router-dom";
import {
	IonPage,
	IonItem,
	IonContent,
	IonToolbar,
	IonLabel,
} from "@ionic/react";
import "./css/Landing.css";

const Landing = () => {
	return (
		<IonPage>
			<IonContent>
				<IonToolbar className="Landing" color="clear">
					<IonLabel className="greeting">Welcome</IonLabel>
					<Link to="/home" style={{ textDecoration: "none" }}>
						<IonItem className="enter" lines="none" color="clear">
							<IonLabel>click to enter</IonLabel>
						</IonItem>
					</Link>
				</IonToolbar>
			</IonContent>
		</IonPage>
	);
};

export default Landing;
