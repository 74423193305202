import React, { useState, useEffect } from "react";
import MenuButton from "components/MenuButton";
import { fetchImages } from "components/Slide";
import Spinner from "assets/tess.gif";
import {
	IonPage,
	IonContent,
	IonItem,
	IonRow,
	IonCol,
	IonImg,
} from "@ionic/react";

const Films = () => {
	// const [urls, setUrls] = useState<string[]>([]);
	const [spinner, setSpinner] = useState<boolean>(true);

	useEffect(() => {
		fetchImages("bts").then((res) => {
			// setUrls(res);
			setSpinner(false);
		});
	}, []);

	return (
		<IonPage>
			<MenuButton />
			{spinner ? (
				<IonRow>
					<IonCol> </IonCol>
					<IonCol>
						<IonItem
							color="clear"
							lines="none"
							style={{
								display: "block",
								marginLeft: "auto",
								marginRight: "auto",
								width: "100%",
								height: "50vh",
							}}
						>
							<IonImg src={Spinner} />
						</IonItem>
					</IonCol>
					<IonCol> </IonCol>
				</IonRow>
			) : (
				<IonContent>
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/r9Reru8zjZI"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
					<br />
					<br />
					<br />
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/dTHQg_robe4"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
					<br />
					<br />
					<br />
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/6J-5QMvILIA"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
					<br />
					<br />
					<br />
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/MxQeCCJdua8"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
					<br />
					<br />
					<br />
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/0-lbktauxAM"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
					<br />
					<br />
					<br />
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/BLRznKaN-rY"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
					<br />
					<br />
					<br />
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/TJj9CCadTfA"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
					<br />
					<br />
					<br />
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/s6zUXcYPBd4"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</IonContent>
			)}
		</IonPage>
	);
};

export default Films;
