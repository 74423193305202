import React from "react";
import { Link } from "react-router-dom";
import CartComponent from "./Cart";
import {
	IonButtons,
	IonHeader,
	IonMenuButton,
	IonToolbar,
	IonImg,
	IonRow,
	IonCol,
} from "@ionic/react";
import TypedLogo from "assets/logoo.svg";

const MenuButton = () => {
	const homePage = window.location.pathname;
	return (
		<IonHeader>
			<IonToolbar>
				<IonButtons slot="start">
					<IonMenuButton color="medium" />
				</IonButtons>
				<IonRow>
					<IonCol>
						<Link to="/home">
							<IonImg
								src={TypedLogo}
								alt="Free Space"
								className="logo"
								style={{ height: 50, width: 100 }}
							/>
						</Link>
					</IonCol>
					{homePage !== "/home" && homePage !== "/about" && (
						<IonCol>
							<CartComponent />
						</IonCol>
					)}
				</IonRow>
			</IonToolbar>
		</IonHeader>
	);
};

export default MenuButton;
