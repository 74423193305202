import React, { useEffect, useState } from "react";
import MenuButton from "components/MenuButton";
import {
	IonPage,
	IonContent,
	IonRow,
	IonCol,
	IonImg,
	IonText,
	IonItem,
} from "@ionic/react";
import Spinner from "assets/tess.gif";
// const h_k =
// "https://firebasestorage.googleapis.com/v0/b/free-site-3.appspot.com/o/0014.jpg?alt=media&token=667d22fc-36e8-41cf-9c35-2e1bd50192bc";
// const h =
// "https://firebasestorage.googleapis.com/v0/b/free-site-3.appspot.com/o/0018.jpg?alt=media&token=518d376e-00c7-40ee-975b-6fde0277d714";
// const k =
// "https://firebasestorage.googleapis.com/v0/b/free-site-3.appspot.com/o/0022.jpg?alt=media&token=98adb939-62c3-4c8e-b60d-a2833ba6842b";

const About = () => {
	// const [h, setH] = useState<string>("");
	// const [k, setK] = useState<string>("");
	const [h_k, setH_K] = useState<string>("");
	const [spinner, setSpinner] = useState<boolean>(true);

	useEffect(() => {
		// setH(
		// 	"https://firebasestorage.googleapis.com/v0/b/free-site-3.appspot.com/o/0018.jpg?alt=media&token=518d376e-00c7-40ee-975b-6fde0277d714"
		// );
		// setK(
		// 	"https://firebasestorage.googleapis.com/v0/b/free-site-3.appspot.com/o/0022.jpg?alt=media&token=98adb939-62c3-4c8e-b60d-a2833ba6842b"
		// );
		setH_K(
			"https://firebasestorage.googleapis.com/v0/b/free-site-3.appspot.com/o/0014.jpg?alt=media&token=667d22fc-36e8-41cf-9c35-2e1bd50192bc"
		);
		setSpinner(false);
	}, []);
	return (
		<IonPage>
			<IonContent>
				<MenuButton />
				{spinner ? (
					<IonRow>
						<IonCol> </IonCol>
						<IonCol>
							<IonItem
								color="clear"
								lines="none"
								style={{
									display: "block",
									marginLeft: "auto",
									marginRight: "auto",
									width: "100%",
									height: "50vh",
								}}
							>
								<IonImg src={Spinner} />
							</IonItem>
						</IonCol>
						<IonCol> </IonCol>
					</IonRow>
				) : (
					<>
						<IonRow>
							<IonCol>
								<IonImg src={h_k} />
							</IonCol>
						</IonRow>
						{/* <IonRow>
							<IonCol>
								<IonImg src={k} />
								<IonItem lines="none">
									<IonNote>(Uchenna Ezeokoli]</IonNote>
								</IonItem>
							</IonCol>
							<IonCol>
								<IonImg src={h} />
								<IonItem lines="none">
									<IonNote>(Hoa Nguyen]</IonNote>
								</IonItem>
							</IonCol>
						</IonRow> */}
						{/* <IonRow> */}
						{/* <IonCol> */}

						<IonRow>
							<IonCol size="1"></IonCol>
							<IonCol>
								<IonItem lines="none">
									<IonText
										style={{
											textAlign: "right",
											marginLeft: "auto",
											paddingBottom: "2%",
										}}
									>
										<br />
										{"from concept... to reality..."} <br /> <br />
										{
											"the continuous effort of bringing the ideas of our inner domain into the physical world..."
										}
										<br /> <br />
										{"the idea is always the genesis..."}
										<br />
										<br />
										{"&"}
										<br />
										<br />
										{"the ideas are endless..."}
										<br />
										<br />- (LEO]
									</IonText>
								</IonItem>
							</IonCol>
							<IonCol size="1"></IonCol>
						</IonRow>

						{/* </IonCol> */}
						{/* </IonRow> */}
					</>
				)}
			</IonContent>
		</IonPage>
	);
};

export default About;
