import React, { useState } from "react";
import { useCart } from "react-use-cart";
import {
	useIonModal,
	IonIcon,
	IonItem,
	IonList,
	IonContent,
	IonButton,
	IonLabel,
	IonImg,
	IonRow,
	IonCol,
	IonBadge,
	IonNote,
	useIonToast,
	IonProgressBar,
} from "@ionic/react";
import {
	cartOutline,
	addCircleOutline,
	removeCircleOutline,
	trashOutline,
} from "ionicons/icons";
import { createCheckoutSession } from "api/StripeAPI";

const CartItems: React.FC<{
	isEmpty: boolean;
	totalUniqueItems;
	items: any[];
	updateItemQuantity;
	removeItem;
	onDismiss;
	show;
	redirect;
	setRedirect;
}> = ({
	isEmpty,
	items,
	updateItemQuantity,
	removeItem,
	onDismiss,
	show,
	redirect,
	setRedirect,
}) => {
	// console.log(items);
	let subTotal: any = 0;
	let Total: any = 0;

	function handleRemoveCart(id: string, name: string) {
		removeItem(id);
		show(name + " removed from cart!", 3000);
	}

	function checkout(items: any) {
		setRedirect(true);
		createCheckoutSession(items);
	}

	return (
		<IonContent>
			{isEmpty ? (
				<IonRow>
					<IonCol>
						<IonItem lines="none" color="none">
							Cart empty!
						</IonItem>
					</IonCol>
					<IonCol>
						<IonItem lines="none" color="clear">
							<IonButton slot="end" color="light" onClick={() => onDismiss()}>
								Close
							</IonButton>
						</IonItem>
					</IonCol>
				</IonRow>
			) : (
				<>
					<IonRow>
						<IonCol>
							<IonItem color="clear" lines="none">
								Cart summary
							</IonItem>
						</IonCol>
						<IonCol>
							<IonItem lines="none" color="clear">
								<IonButton slot="end" color="light" onClick={() => onDismiss()}>
									Close
								</IonButton>
							</IonItem>
						</IonCol>
					</IonRow>
					<IonList color="clear">
						{items.map((item: any) => {
							subTotal += parseFloat(item.price) * item.quantity;
							Total = subTotal + 7.0;
							return (
								<IonItem key={item.id} color="clear">
									<IonRow>
										{/* <IonCol> */}
										<IonImg src={item.thumbnail} />
										{/* </IonCol> */}

										<IonCol>
											<IonLabel>{item.name}</IonLabel>
											<IonNote>{"Price - $" + subTotal.toFixed(2)}</IonNote>
											<IonLabel position="stacked">
												Quantity - {" " + item.quantity}
											</IonLabel>

											<IonItem button lines="none" color="clear">
												<IonIcon
													slot="start"
													onClick={() =>
														updateItemQuantity(item.id, item.quantity + 1)
													}
													icon={addCircleOutline}
												/>
											</IonItem>
											<IonItem button lines="none" color="clear">
												<IonIcon
													onClick={() =>
														updateItemQuantity(item.id, item.quantity - 1)
													}
													icon={removeCircleOutline}
												/>
											</IonItem>
										</IonCol>
									</IonRow>
									<IonButton
										slot="end"
										color="danger"
										fill="clear"
										onClick={() => handleRemoveCart(item.id, item.name)}
									>
										<IonIcon icon={trashOutline} />
									</IonButton>
								</IonItem>
							);
						})}
						<br />
						<IonItem lines="none">
							<IonRow>
								<IonLabel position="stacked">
									Subtotal ${subTotal.toFixed(2)}
								</IonLabel>
							</IonRow>
							<IonRow>
								<IonLabel position="stacked">Shipping $7.00</IonLabel>
							</IonRow>
							<IonRow className="ion-padding-bottom">
								<IonLabel position="stacked">
									Total ${Total.toFixed(2)}
								</IonLabel>
							</IonRow>
						</IonItem>
					</IonList>
					{redirect ? (
						<IonProgressBar type="indeterminate"></IonProgressBar>
					) : (
						<IonButton
							color="success"
							expand="block"
							onClick={() => checkout(items)}
						>
							Checkout
						</IonButton>
					)}
				</>
			)}
		</IonContent>
	);
};

const CartComponent: React.FC = () => {
	const { isEmpty, totalUniqueItems, items, updateItemQuantity, removeItem } =
		useCart();
	const handleDismiss = () => {
		dismiss();
	};
	const [show] = useIonToast();
	const [redirect, setRedirect] = useState<boolean>(false);
	const [present, dismiss] = useIonModal(CartItems, {
		isEmpty,
		totalUniqueItems,
		items,
		updateItemQuantity,
		removeItem,
		onDismiss: handleDismiss,
		show,
		redirect,
		setRedirect,
	});

	return (
		<IonItem
			button
			lines="none"
			color="clear"
			style={{ display: "inline" }}
			onClick={() => present()}
		>
			<IonIcon slot="end" icon={cartOutline} />
			{totalUniqueItems > 0 ? (
				<IonBadge slot="end" color="danger">
					{totalUniqueItems}
				</IonBadge>
			) : (
				<></>
			)}
		</IonItem>
	);
};

export default CartComponent;
