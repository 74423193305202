import React, { useEffect } from "react";
import { useCart } from "react-use-cart";
import { retrieveCheckoutSession } from "api/StripeAPI";
import { postOrders } from "api/printfulApi";
import {
	IonPage,
	IonLabel,
	IonItem,
	IonImg,
	IonRow,
	IonCol,
} from "@ionic/react";
import Spinner from "assets/tess.gif";
import "pages/Landing/css/Landing.css";

// const vid =
// "https://firebasestorage.googleapis.com/v0/b/free-site-3.appspot.com/o/Tell%20them%20to%20bring%20me%20my%20money%20meme.mp4?alt=media&token=6d312984-ca28-4944-862c-dd128b578ead";

const Checkout = () => {
	const { items, emptyCart } = useCart();

	useEffect(() => {
		let id;
		let newOrder: any;

		id = window.localStorage.getItem("checkout_session_id");
		// console.log(id);

		retrieveCheckoutSession(id).then((response) => {
			console.log(response);
			if (response.payment_status === "paid") {
				newOrder = {
					recipient: {
						name: response.name,
						address1: response.address.line1,
						city: response.address.city,
						state_code: response.address.state,
						country_code: response.address.country,
						zip: response.address.postal_code,
						email: response.email,
					},

					items: items.map((item: any) => {
						return {
							external_variant_id: item.id,
							quantity: item.quantity,
						};
					}),
				};
				postOrders(newOrder).then((response) => {
					emptyCart();
					window.localStorage.removeItem("checkout_session_id");
					window.location.href = "http://www.furisupesu.com/shop";
				});

				// console.log(newOrder);
			}
		});
	}, [emptyCart, items]);

	return (
		<IonPage>
			<IonRow>
				<IonCol> </IonCol>
				<IonCol>
					<IonItem
						color="clear"
						lines="none"
						style={{
							display: "block",
							marginLeft: "auto",
							marginRight: "auto",
							width: "100%",
							height: "50vh",
						}}
					>
						<IonLabel position="stacked">
							Thank you for shopping with FREE_SPACE (...]
						</IonLabel>
						<IonImg src={Spinner} />
					</IonItem>
				</IonCol>
				<IonCol> </IonCol>
			</IonRow>
		</IonPage>
	);
};

export default Checkout;
