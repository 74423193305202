import axios from "axios";

export const createCheckoutSession = async (items: any) => {
	const dest =
		"https://us-central1-free-site-3.cloudfunctions.net/createStripeCheckout";
	// "http://localhost:5001/free-site-3/us-central1/createStripeCheckout";

	return axios({
		method: "post",
		url: dest,
		headers: { "Access-Control-Allow-Origin": "*" },
		data: {
			items: items.map((item: any) => {
				return { id: item.id, quantity: item.quantity };
			}),
		},
	})
		.then((response: any) => {
			window.location.href = response.data.url;
			window.localStorage.setItem("checkout_session_id", response.data.id);
		})
		.catch((error: any) => {
			console.log(error);
		});
	// fetch(dest, {
	// method: "POST",

	// body: JSON.stringify({
	// items: items.map((item: any) => {
	// return { id: item.id, quantity: item.quantity };
	// }),
	// }),
	// })
	// .then((response) => {
	// return response.json();
	// })
	// .then((session) => {
	// window.location.href = session.url;
	// window.localStorage.setItem("checkout_session_id", session.id);
	// });
};

export const retrieveCheckoutSession = async (id: string) => {
	const dest =
		"https://us-central1-free-site-3.cloudfunctions.net/retrieveCheckoutSession";
	return axios({
		method: "post",
		url: dest,
		headers: { "Access-Control-Allow-Origin": "*" },
		data: { id: id },
	})
		.then((response: any) => {
			return response.data;
		})
		.catch((error: any) => {
			console.log(error);
		});
};
