import React, { useState } from "react";
import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import Menu from "components/Menu";
import Landing from "pages/Landing";
import Home from "pages/Home";
import Shop from "pages/Shop";
import ProductView from "pages/ProductView";
import Vision from "pages/Vision";
import Checkout from "pages/Checkout";
import About from "pages/About";
import Films from "pages/Films";
import Fotos from "pages/Fotos";

// interface Props {
// playVid: boolean;
// }
const Routes = () => {
	const [playVid, setPlayVid] = useState<boolean>(false);

	function isPlaying() {
		if (window.location.pathname === "/vision") {
			setPlayVid(true);
		} else {
			setPlayVid(false);
		}
		// console.log(window.location.pathname, playVid);
	}

	return (
		<IonReactRouter>
			<Menu />
			<IonRouterOutlet id="main" onPointerEnter={() => isPlaying()}>
				<Route path="/" exact={true} component={Landing} />
				<Route path="/home" exact={true} render={() => <Home />} />
				<Route path="/vision" exact={true}>
					<Vision playVid={playVid} />
				</Route>
				<Route path="/shop" exact={true} render={() => <Shop />} />
				<Route path="/shop/:id" exact={true} component={ProductView} />
				<Route path="/films" exact={true} component={Films} />
				<Route path="/fotos" exact={true} component={Fotos} />
				<Route path="/about" exact={true} component={About} />
				<Route path="/checkout" exact={true} component={Checkout} />
			</IonRouterOutlet>
		</IonReactRouter>
	);
};

export default Routes;
