import React, {
	// useState,
	useEffect,
} from "react";
import {
	IonPage,
	IonImg,
	IonItem,
	IonRow,
	IonCol,
	IonContent,
} from "@ionic/react";
import MenuButton from "components/MenuButton";
import FREE_D from "../../assets/FREE-D.gif";
import "./css/Home.css";
import { fetchImages } from "components/Slide";

const Home = () => {
	// const [urls, setUrls] = useState<string[]>([]);

	// console.log("storage", storage.ref().child("featured").listAll());

	useEffect(() => {
		fetchImages("homepage").then((res) => {
			// setUrls(res);
		});
	}, []);

	return (
		<IonPage>
			<MenuButton />
			<IonContent>
				<IonRow>
					<IonCol> </IonCol>
					<IonCol>
						<IonItem color="clear" lines="none">
							<IonImg
								src={FREE_D}
								style={{
									paddingTop: "10vh",
									width: "100vh",
									overflow: "hidden",
								}}
								alt="FREE_SPACE"
							/>
						</IonItem>
					</IonCol>
					<IonCol> </IonCol>
				</IonRow>
			</IonContent>
		</IonPage>
	);
};

export default Home;
