import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSyncVariants } from "api/printfulApi";
import MenuButton from "components/MenuButton";
import { useCart } from "react-use-cart";
import Spinner from "assets/tess.gif";
import Slide from "components/Slide";
import { db } from "../../firebase";

import {
	IonPage,
	IonContent,
	IonImg,
	IonToolbar,
	IonSelect,
	IonLabel,
	IonItem,
	IonSelectOption,
	IonButton,
	IonInput,
	IonBackButton,
	IonRow,
	IonCol,
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardContent,
	useIonToast,
} from "@ionic/react";

const ProductView = () => {
	const { addItem } = useCart();
	const [variants, setVariants] = useState<any>();
	const [images, setImages] = useState<string[]>([]);
	const [details, setDetails] = useState<string>("");
	const [item, setItem] = useState<any>({
		specs: { id: "", price: "", size: "", name: "", thumbnail: "" },
	});
	const [quantity, setQuantity] = useState<number>(1);
	const { id } = useParams<any>();
	const [present] = useIonToast();
	const [spinner, setSpinner] = useState<boolean>(true);

	// console.log("State", item);
	// console.log("useCart", items.length);

	useEffect(() => {
		console.log(id);
		db.collection("printfulProducts")
			.where("external_id", "==", id)
			.get()
			.then(async (col) => {
				// console.log(
				// 	"product",
				// 	col.docs.map((doc) => doc.data())
				// );
				setDetails(col.docs.map((doc) => doc.data())[0].details);
				setImages(col.docs.map((doc) => doc.data())[0].images);
				let newId = `@${id}`;
				getSyncVariants(newId).then((res) => {
					setVariants(res);
					console.log("id", res);
					setSpinner(false);
				});
			});
		// console.log(images);
	}, [id]);

	function handleAddCart() {
		if (item.specs.id !== "") {
			addItem(item.specs, quantity);
			present(item.specs.name + " added to cart!", 1000);
		} else {
			present("Please select a size!", 3000);
		}
	}

	return (
		<IonPage>
			<MenuButton />
			{spinner ? (
				<IonRow>
					<IonCol> </IonCol>
					<IonCol>
						<IonItem
							color="clear"
							lines="none"
							style={{
								display: "block",
								marginLeft: "auto",
								marginRight: "auto",
								width: "100%",
								height: "50vh",
							}}
						>
							<IonImg src={Spinner} />
						</IonItem>
					</IonCol>
					<IonCol> </IonCol>
				</IonRow>
			) : (
				<IonContent>
					<IonRow>
						<IonBackButton
							defaultHref="/shop"
							text="Back"
							className="ion-padding"
						/>
					</IonRow>
					<IonRow>
						<IonCol></IonCol>
						<IonCol>
							{variants && (
								<IonToolbar color="clear">
									{/* replace with Slide component */}
									<Slide
										urls={images}
										transition="fade"
										color="dark"
										autoplay={false}
										note={variants.result.sync_product.name}
										pager={images.length > 1 ? true : false}
									/>
									<IonCard>
										<IonCardHeader>
											<IonCardSubtitle>Details:</IonCardSubtitle>
										</IonCardHeader>
										<IonCardContent>{details}</IonCardContent>
									</IonCard>
									{/* <IonItem>
										<IonText> Details:</IonText>
										<IonText>{details}</IonText>
									</IonItem> */}
									<IonItem>
										<IonLabel position="floating">Select Size</IonLabel>
										<IonSelect
											selected-text={item.specs.size}
											onIonChange={(e) =>
												setItem({ ...item, specs: e.detail.value })
											}
										>
											{variants &&
												variants.result.sync_variants.map((product: any) => {
													return (
														<IonSelectOption
															key={product.name}
															value={{
																id: product.external_id,
																name: product.name,
																size: product.name.substring(
																	product.name.lastIndexOf("-") + 1
																),
																price: product.retail_price,
																thumbnail:
																	product.files[product.files.length - 1]
																		.thumbnail_url,
															}}
														>
															{product.name.substring(
																product.name.lastIndexOf("-") + 1
															)}
														</IonSelectOption>
													);
												})}
										</IonSelect>
									</IonItem>
									<IonItem>
										<IonLabel position="floating">Quantity</IonLabel>
										<IonInput
											type="number"
											value={quantity}
											min={"1"}
											onIonChange={(e) =>
												setQuantity(parseFloat(e.detail.value!))
											}
										></IonInput>
									</IonItem>
									<IonButton
										color="light"
										style={{ display: "block" }}
										onClick={() => handleAddCart()}
									>
										Add to Cart ${" "}
										{parseFloat(variants.result.sync_variants[0].retail_price) *
											quantity}
									</IonButton>
								</IonToolbar>
							)}
						</IonCol>
						<IonCol></IonCol>
					</IonRow>
				</IonContent>
			)}
		</IonPage>
	);
};

export default ProductView;
